<template>
    <div>
      <div class="center" v-if="showLoading">
          <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
      </div>
       <div class="home" v-else>
          <headerSection></headerSection>
          <main class="mqbg">
              <div class="gl-content">
                  <!-- start top bookmark -->
                  <div id="here_top">&nbsp;</div>
                  <!-- end top bookmark -->
              </div>
              <!-- start content -->
              <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                  <div class="container">
                      <div class="spin">
                          <div style="position: absolute; bottom: 20px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                              <div style="display: flex; flex: 1; align-items: center; justify-content: center;">
                                  <div style="border-radius: 50px; margin-left: 0px; background: #2f2f2f; align-items: center; justify-content: center; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 9px; padding-bottom: 9px;">
                                      <span class="glow" ref="myBtn">
                                      {{loadingtext}}
                                      </span>
                                  </div>
                              </div>
                          </div>
                          <div style="position: absolute; top: 90px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;" @click="openGift">
                            <lottie :options="defaultOptions" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
                            </div>

                            <div style="position: absolute; bottom: 38px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                            <lottie :options="defaultOptionsSanta" :height="300" :width="300" v-on:animCreated="handleAnimationSanta"/>
                            </div>

                      </div>
  
                  </div>
              </div>
            </main>
  <!--
          <div class="x-button-actions" id="account-actions-mobile" style="position: fixed; left: 0; right: 0; bottom: 0;">
                  <div class="d-flex">
                      <div class="-btn-container -withdraw-container">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
                              <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="EZ Slot ถอนเงิน" class="-icon img-fluid">
                              <div class="-typo">ถอนเงิน</div>
                          </a>
                      </div>
                      <div class="-btn-container -lobby-container">
                          <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
                              <div class="-img-container">
                                  <img src="../assets/images/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                                  <img src="../assets/images/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                              </div>
                              <div class="-typo">หน้าเกมส์</div>
                          </a>
                      </div>
                      <div class="-btn-container -deposit-container">
                          <a href="javascript:void(0);" data-toggle="modal" data-target="#depositChoosePromotionModal" class="btn btn-lg btn-plain-secondary js-account-approve-aware">
                              <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="EZ Slot ฝากเงิน" class="-icon img-fluid">
                              <div class="-typo">ฝากเงิน</div>
                          </a>
                      </div>
                  </div>
              </div>
  -->
          <footerSection></footerSection>
        </div>
    </div>
  </template>
  <script>
  import '../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css'
  import '../assets/fonts/iconic/css/material-design-iconic-font.min.css'
  //import '../assets/css/main.css'
  import '../assets/css/util.css'
  import firebase, { database } from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/database'
  import 'firebase/auth'
  import _ from 'lodash'
  // const axios = require("axios").default;
  import moment from 'moment-timezone'
  import router from './../router'
  import Lottie from './lottie.vue'
  import * as animBox from '../assets/chinese.json'
//   import * as animOpenBox from '../assets/open_coins_box5.json'
  import * as animOpenSanta from '../assets/chinese2.json'
  import * as animateSantaWalk from '../assets/loading.json'
  require('vue2-animate/dist/vue2-animate.min.css')
  const axios = require('axios').default
  const audio2 = new Audio(require('../assets/chinese.mp3'))
  // @ is an alias to /src
  export default {
    name: 'Angpao',
    created () {
      const user = localStorage.getItem('userData')
      if (user) {
            const _user = JSON.parse(user)
  
            this.$store.dispatch('user/setUser', _user).then(() => {
          this.checkUserId(_user)
        }).catch(err => { console.error(err) })
        } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/')
        }
      document.body.style.backgroundColor = '#9CD0DF'
      const animate = animBox.default
    //   const animateOpenBox = animOpenBox.default
      const animateSanta = animOpenSanta.default
      const animateSplashScreen = animateSantaWalk.default
      this.defaultOptions = { animationData: animate, autoplay: false, loop: true }
    //   this.defaultOptions2 = { animationData: animateOpenBox, autoplay: false, loop: false }
      this.defaultOptionsSanta = { animationData: animateSanta, autoplay: false, loop: true }
      this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
    },
    computed: {
      user () {
        return this.$store.state.user.user
      }
    },
    mounted () {
      if (!firebase.auth().currentUser) {
        router.replace('/')
      } else {
        this.animSplashScreen.play()
        setTimeout(() => {
          this.showLoading = false
          this.animSplashScreen.stop()
          setTimeout(() => {
            audio2.volume = 0.6
            audio2.play()
            this.anim.play()
            this.animSanta.play()
          }, 500)
        }, 3500)
      }
    },
    data () {
      return {
        gameKey: 'angpao',
        logoLeft:'',
        logoRight:'',
        showLoading: true,
        pictureUrl: '',
        code: '',
        games_web: [],
        search: '',
        loading: false,
        error: false,
        web: [],
        message: 'Invalid user name or password!',
        point: 0,
        favorites: [],
        animationInfinite: {
          classes: 'fadeIn',
          duration: 5000,
          iteration: 'infinite'
        },
        permission: [],
        loadingtext: '',
        defaultOptions: null,
        defaultOptions2: null,
        defaultOptionsSplashScreen: null,
        openGiftData: false,
        canOpen: false
      }
    },
    components: {
      lottie: Lottie,
      headerSection: () => import('./Header.vue'),
      footerSection: () => import('./Footer.vue')
    },
    methods: {
      logout(){
          this.$confirm(
            {
              message: `ต้องการล็อกเอาท์จากระบบ`,
              button: {
                yes: 'Yes',
                no: 'No'
              },
              callback: confirm => {
                if (confirm) {
                  firebase.auth().signOut().then(()=>{
                    router.replace('/')
                  })
                }
              }
            }
          );
      },
      randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      },
      async openGift () {
        if (this.canOpen && this.user && this.gameKey) {
          this.canOpen = false
          this.anim.play();
        //   this.anim2.show()
        //   this.anim2.play()
          const qty = this.randomIntFromInterval(this.games_web.from, this.games_web.to)
          axios({
              method: 'post',
              url: 'https://api.banana789.co/qspin/angpao',
              data: {
                key: this.user.key,
                code: this.user.code,
                value: qty,
                type: this.games_web.type,
                cal: this.games_web.cal
              }
            })
            setTimeout(() => {
              this.$confirm(
                {
                  message: `🧧 ปังปุรีเย่!! คุณได้รับ ${qty} ${this.games_web.type}😘😘`,
                  button: {
                    yes: 'OK'
                  },
                  callback: async (confirm) => {
                    if (confirm) {
                      this.$router.push('/')
                    }
                  }
                })
            }, 1000)
        }
      },
      handleAnimationSplashScreen: function (anim) {
        this.animSplashScreen = anim
      },
      handleAnimation: function (anim) {
        this.anim = anim
      },
      handleAnimation2: function (anim2) {
        this.anim2 = anim2
      },
      handleAnimationSanta: function (anim) {
        this.animSanta = anim
      },
      async checkUserId (user) {
        this.games_web = (await firebase.database().ref('/games_web/'+this.gameKey).once('value')).val()
        if (this.games_web.status && user.key) {
          if (!this.games_web.cal || this.games_web.cal === 'manual') {
            const permission = (await firebase.database().ref(`/users/${user.key}/games/angpao`).once('value')).val()
            if (permission) {
              this.canOpen = true
            } else {
              this.$swal({ icon: 'error', title: 'สิทธิ์การเข้าเล่น!', text: 'คุณยังไม่มีสิทธิ์เล่นค่ะ โปรดติดต่อแอดมิน!!!' })
              router.replace('/event')
            }
          } else {
            await firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-angpao').get().then(async (snapshot) => {
              if (snapshot.exists) {
                this.$confirm(
                  {
                    message: '🧧 ลูกค้าเปิดซองของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
                    button: {
                      yes: 'OK'
                    },
                    callback: confirm => {
                      if (confirm) {
                        this.$router.push('/event')
                      }
                    }
                  }
                )
              } else {
                await firebase.firestore().collection('history').where('userkey', '==', user.key).where('type', '==', 'ฝาก').where('status', '==', 'ดำเนินการแล้ว').where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').where('createdate', '<=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 23:59:59').get().then(async (snapshot) => {
                  let credit = 0
                  snapshot.forEach((doc) => {
                    credit += Number(doc.data().qty)
                  })
                  const deposit = (this.games_web.deposit) ? Number(this.games_web.deposit) : 0
                  if (credit >= deposit) {
                    this.canOpen = true
                  } else {
                    this.canOpen = true
                    const less = deposit - Number(credit)
                    this.loadingtext = `เติมเงินอีก ${less}(${deposit}) ได้เล่นเลยค่ะ!😘😘`
                  }
                })
              }
            })
          }
        }
      },
      checkCssBox (game) {
        if (!game.status) {
          return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
        }
      },
      checkCss (game) {
        if (!this.permission || !this.permission[game.key]) {
          return 'filter: grayscale(100%);'
        }
        // const index = _.findIndex(this.favorites, function(o) { return o == code; });
        // if (index>-1) {
        //   return '';
        // } else {
        //   return 'filter: grayscale(100%);';
        // }
      },
      favorite (gameCode) {
        const index = _.findIndex(this.favorites, function (o) { return o == gameCode })
        if (this.userId) {
          if (index > -1) {
            firebase.database().ref('favorites/' + this.userId + '/' + gameCode).remove()
            this.favorites.splice(index, 1)
          } else {
            firebase.database().ref('favorites/' + this.userId + '/' + gameCode).push({
              gameCode
            })
            this.favorites.push(gameCode)
          }
        }
      },
      goto (type) {
        if (type === 'home') {
          // window.scrollTo({
          //   top: 0,
          //   left: 0,
          //   behavior: 'smooth'
          // });
          this.$router.push('/games').catch(() => {})
        } else if (type === 'website') {
          window.location = this.web.link
          // this.gotoline()
        } else if (type === 'contact') {
          window.location = this.web.link_line
          // this.gotoline()
        }
      },
      getSpinClass () {
        if (!this.loading) { return 'fas fa-sync-alt f-9' } else { return 'fas fa-sync-alt fa-spin f-9' }
      },
  
      numberWithCommas (x) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      openGame (game) {
        if (this.permission[game.key]) {
          if (game.key === 'angpao') {
            this.$router.push('/angpao').catch(() => {})
          }
        }
      }
    }
  }
  </script>
  <style scoped>
  .center {
      position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #contact_gl {
    min-height: 600px;
    color: #333;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
      /*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
      background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
      background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
  }
  #contact_gl::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
  }
  .boxlogin {
      background: #fff;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      box-shadow: 0 0 40px #000;
      color: #8b8c8d;
      font-size: 0;
  }
  .boxlogin .box-part {
      display: inline-block;
      position: relative;
      vertical-align: top;
      height: 100%;
  }
  .boxlogin, .box .box-part {
      box-sizing: border-box;
  }
  .boxlogin .partition {
      width: 100%;
      height: 100%;
  }
  .boxlogin .partition .partition-title {
      box-sizing: border-box;
      padding: 30px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 20px;
      font-weight: 300;
  }
  .boxlogin .partition .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
  }
  .boxlogin .button-set {
      margin-bottom: 8px;
  }
  .boxlogin .github-btn {
      border-color: #dba226;
      color: #dba226;
  }
  .boxlogin .large-btn {
      width: 100%;
      background: #fff;
  }
  .boxlogin button {
      background: #fff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px;
      letter-spacing: 1px;
      font-family: Open Sans,sans-serif;
      font-weight: 400;
      min-width: 140px;
      margin-top: 8px;
      color: #8b8c8d;
      cursor: pointer;
      border: 1px solid #dddedf;
      text-transform: uppercase;
      transition: all .1s;
      font-size: 10px;
      outline: none;
  }
   .p2 {
       letter-spacing: 0;
       text-shadow: 0 0 80px rgba(255, 255, 255, .5);
      /* Clip Background Image */
       background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
       -webkit-background-clip: text;
       background-clip: text;
      /* Animate Background Image */
       -webkit-text-fill-color: transparent;
       -webkit-animation: aitf 80s linear infinite;
      /* Activate hardware acceleration for smoother animations */
       -webkit-transform: translate3d(0, 0, 0);
       -webkit-backface-visibility: hidden;
  }
  /* Animate Background Image */
   @-webkit-keyframes aitf {
       0% {
           background-position: 0% 50%;
      }
       100% {
           background-position: 100% 50%;
      }
  }
  </style>
  